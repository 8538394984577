<template>
  <div @click.self="closeModal" class="modalContainer">
    <div class="modalWrapper">
      <div @click="closeModal" class="close">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1161 6.6228L7.75908 6.97635L8.11437 7.33163L13.4572 12.6745L12.6745 13.4572L11.8466 12.6294L11.8449 12.6277L7.37718 8.11608L7.02364 7.75906L6.66835 8.11435L1.32551 13.4572L0.542786 12.6745L5.88563 7.33163L6.24062 6.97664L5.8842 6.62309L0.543158 1.32514L1.32551 0.542787L6.66835 5.88563L7.02334 6.24061L7.37689 5.88419L12.6748 0.543155L13.4572 1.32551L12.6294 2.15333L12.6277 2.15504L8.1161 6.6228ZM12.7648 13.5475C12.7649 13.5476 12.765 13.5478 12.7651 13.5479L12.7648 13.5475ZM0.452113 12.7651L0.452145 12.7651C0.452134 12.7651 0.452123 12.7651 0.452113 12.7651Z"
            fill="black"
            stroke="black"
          />
        </svg>
      </div>
      <h2>{{ phoneInputVisible ? 'Change Phone' : 'Verify Phone' }}</h2>
      <p v-if="phoneInputVisible" class="description">
        To change your phone number, enter your phone number and click update. To go back
        <span class="clickHere" @click="togglePhoneInput">CLICK HERE</span>
      </p>
      <p v-else class="description">
        Please enter the PIN sent to
        <span class="bold">{{ formatPhoneNumber(customerData.phone) }}</span>
        below to validate your account. If you want to change your phone number
        <span class="clickHere" @click="togglePhoneInput">CLICK HERE</span>
      </p>
      <div v-if="phoneInputVisible" class="phoneChange">
        <div class="inputWrapper">
          <label for="phone">Cell Phone Number</label>
          <input
            @keyup="formatPhoneNumberWhileTyping(phone)"
            v-model="phone"
            type="text"
            placeholder="Cell Phone Number"
            id="phone"
            inputmode="tel"
          />
          <p v-if="v$.phone.$error === true" class="validation">Please enter your cell phone number</p>
          <p v-if="validPhoneNumberError" class="validation" data-cy="phone-validation-error">
            Please enter a valid phone number. Phone number can't start with 1
          </p>
        </div>
        <p class="feedback error" v-if="error.status && phoneInputVisible">
          {{ error.message }}
        </p>
        <button @click="changePhone">
          <div v-if="buttonLoader" class="loader buttonLoader"></div>
          <template v-if="!buttonLoader">UPDATE</template>
        </button>
      </div>
      <div v-if="!phoneInputVisible" class="pin">
        <Verification
          type="text"
          activeInputClass="active-input-class"
          @on-complete="onCompleteHandler"
          @on-paste="onPasteHandler"
        />
      </div>
      <p v-if="!codeIsValid && !phoneInputVisible" class="validation">Invalid pin code</p>
      <a @click="sendVerificationCode(true)" class="resend" href="javascript:;" v-if="!phoneInputVisible">
        <p>RESEND PIN</p>
      </a>
      <p class="feedback error" v-if="error.status && !phoneInputVisible">
        {{ error.message }}
      </p>
      <button class="verify" @click="verifyPhone" :disabled="pinLoading" v-if="!phoneInputVisible">
        <div v-if="pinLoading" class="loader buttonLoader"></div>
        <template v-if="!pinLoading"> VERIFY </template>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import formattingMixins from '@/mixins/formattingMixins'

import Verification from './Verification.vue'

import modalMixins from '@/mixins/modalMixins.js'

import customersApi from '@/api/customersApi.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      pin: '',
      phone: '',
      phoneInputVisible: false,
      codeIsValid: true,
      error: {
        status: false,
        message: '',
      },
      pinLoading: false,
      validPhoneNumberError: false,
    }
  },
  components: {
    Verification,
  },
  validations() {
    return {
      phone: { required },
    }
  },
  mixins: [modalMixins, formattingMixins],
  computed: {
    ...mapState(['customerData', 'buttonLoader', 'cookies', 'branding']),
  },
  methods: {
    ...mapMutations(['SET_TOASTER', 'SET_CUSTOMER_DATA', 'SET_PHONE_VERIFIED', 'SET_BUTTON_LOADER', 'SET_COOKIES']),
    togglePhoneInput() {
      this.error.status = false
      this.phone = ''
      this.phoneInputVisible = !this.phoneInputVisible
    },
    checkValidation() {
      return Object.values(this.pin).join('').length === 6
    },
    closeModal() {
      this.$emit('closeModal')
    },
    onCompleteHandler(e) {
      this.pin = e
    },
    onPasteHandler(e) {
      this.pin = e
    },
    sendVerificationCode(resend) {
      let payload = {
        phone: this.customerData.phone,
      }
      customersApi
        .sendVerificationCode(payload)
        .then((response) => {
          if (response.data.data.ok) {
            if (resend) {
              this.error.status = true
              this.error.message = 'Verification PIN sent again'
            }
          }
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },
    verifyPhone() {
      if (this.pin.length != 6) {
        this.codeIsValid = false
        return
      }
      this.pinLoading = true
      this.error.status = false
      this.error.message = ''

      let payload = {
        phone: this.customerData.phone,
        security_code: this.pin,
      }

      customersApi
        .verifyPhone(payload)
        .then((response) => {
          if (response.data.data.ok) {
            this.SET_PHONE_VERIFIED(true)
            localStorage.setItem('phoneVerified', true)
            this.getCustomerInfo()
          } else if (!response.data.data.ok) {
            this.pinLoading = false
            this.error.status = true
            this.error.message = 'Verification failed. Please try again.'
          }
          this.SET_COOKIES(response.data.cookies)
        })
        .catch((error) => {
          this.pinLoading = false
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },
    getCustomerInfo() {
      customersApi
        .getCustomerInfo()
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          this.pinLoading = false
          this.$emit('closeModal')
          let toasterData = {
            show: true,
            message: 'Phone number verified',
          }
          this.SET_TOASTER(toasterData)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.pinLoading = false
          this.loading = false
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
    changePhone() {
      // check if all fields are valid
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }
      if (this.phone.startsWith('(1')) {
        this.validPhoneNumberError = true
        return
      }
      this.validPhoneNumberError = false
      this.SET_BUTTON_LOADER(true)

      let customerID = localStorage.getItem('customerId')
      let payload = {
        email: this.customerData.email,
        first_name: this.customerData.first_name,
        last_name: this.customerData.last_name,
        // remove all non-digits before sending to the api
        phone: this.phone.replace(/\D/g, ''),
        birthdate: this.customerData.birthdate,
        email_optin: true,
      }

      customersApi
        .updateCustomer(payload, customerID)
        .then((response) => {
          this.SET_CUSTOMER_DATA(response.data.data)
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
          let toasterData = {
            show: true,
            message: 'Verification code sent to the new phone number',
          }
          this.SET_TOASTER(toasterData)
          this.sendVerificationCode()
          this.togglePhoneInput()
        })
        .catch((error) => {
          if (error.response.data.status == 403) {
            let toasterData = {
              show: true,
              message: 'Your session has expired. Please log in again',
            }
            this.SET_TOASTER(toasterData)
            this.$emit('logout')
          }
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          this.error.message = error.response.data.data.error
        })
    },
  },
  created() {
    this.sendVerificationCode()
  },
}
</script>

<style src="../../../assets/css/Modals/AuthenticationModals/PhoneVerificationModal.css" scoped></style>
