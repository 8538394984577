<template>
  <div @click.self="closeModal" class="modalContainer" data-cy="forgot-password-modal">
    <div class="modalWrapper">
      <div @click="closeModal" class="close" data-cy="close-forgot-password-modal">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.1161 6.6228L7.75908 6.97635L8.11437 7.33163L13.4572 12.6745L12.6745 13.4572L11.8466 12.6294L11.8449 12.6277L7.37718 8.11608L7.02364 7.75906L6.66835 8.11435L1.32551 13.4572L0.542786 12.6745L5.88563 7.33163L6.24062 6.97664L5.8842 6.62309L0.543158 1.32514L1.32551 0.542787L6.66835 5.88563L7.02334 6.24061L7.37689 5.88419L12.6748 0.543155L13.4572 1.32551L12.6294 2.15333L12.6277 2.15504L8.1161 6.6228ZM12.7648 13.5475C12.7649 13.5476 12.765 13.5478 12.7651 13.5479L12.7648 13.5475ZM0.452113 12.7651L0.452145 12.7651C0.452134 12.7651 0.452123 12.7651 0.452113 12.7651Z"
            fill="black"
            stroke="black"
          />
        </svg>
      </div>
      <a @click="backToLogin" class="back" data-cy="back-to-login-link">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.521 415.783">
          <path
            id="Path_19208"
            data-name="Path 19208"
            d="M460.94,81.871a33.6,33.6,0,0,0-47.546,0l-174.33,174.39a33.6,33.6,0,0,0,0,47.484l174.39,174.39A33.6,
              33.6,0,0,0,461,430.647L310.3,280.007l150.64-150.64a33.605,33.605,0,0,0,0-47.488Z"
            transform="translate(-229.24 -72.016)"
          />
        </svg>
        <p>BACK TO LOGIN</p>
      </a>
      <img class="logo" :src="getMenuLogo" alt="companyLogo" />
      <h2 style="text-align: center">Forgot Your Password?</h2>
      <div class="signUp">
        <p>Don't have an account?</p>
        <a @click="signUpClicked" href="javascript:;" data-cy="create-account-link"> CREATE AN ACCOUNT </a>
      </div>
      <div class="inputWrapper">
        <label for="email">Email</label>
        <input
          @keyup.enter="resetPassword"
          v-model="forgotPassword.email"
          type="email"
          placeholder="Your Email Address"
          id="email"
          data-cy="forgot-password-email-input"
        />
        <p v-if="v$.forgotPassword.email.$error === true" class="validation" data-cy="email-validation-error">
          Please enter your email address
        </p>
      </div>
      <p class="feedback success" v-if="emailSent" data-cy="password-reset-success-message">
        Password reset email sent
      </p>
      <p class="feedback error" v-if="error.status" data-cy="password-reset-error-message">{{ error.message }}</p>
      <button @click="resetPassword" :disabled="buttonLoader" data-cy="reset-password-button">
        <div v-if="buttonLoader" class="loader buttonLoader"></div>
        <template v-if="!buttonLoader">RESET PASSWORD</template>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import modalMixins from '@/mixins/modalMixins.js'

import customersApi from '@/api/customersApi.js'

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      forgotPassword: {
        email: '',
      },
      emailSent: false,
      error: {
        status: false,
        message: '',
      },
    }
  },
  validations() {
    return {
      forgotPassword: {
        email: { required, email },
      },
    }
  },
  mixins: [modalMixins],
  computed: {
    ...mapState(['buttonLoader', 'branding', 'cookies']),
    getMenuLogo() {
      return this.branding.menu_logo_url
    },
  },
  methods: {
    ...mapMutations(['SET_BUTTON_LOADER', 'SET_COOKIES']),
    resetPassword() {
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }
      this.emailSent = false
      this.error.status = false
      this.error.message = ''
      this.SET_BUTTON_LOADER(true)

      let payload = {
        email: this.forgotPassword.email,
        reset: true,
      }

      customersApi
        .resetPassword(payload)
        .then((response) => {
          if (response.data.data.ok) {
            this.emailSent = true
          } else {
            this.error.status = true
            this.error.message = 'Email could not be sent'
          }
          this.SET_BUTTON_LOADER(false)
          this.SET_COOKIES(response.data.cookies)
        })
        .catch(() => {
          this.SET_BUTTON_LOADER(false)
          this.error.status = true
          this.error.message = 'Something went wrong. Please try again'
        })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    signUpClicked() {
      this.$emit('closeModal')
      this.$emit('signUpClicked')
    },
    backToLogin() {
      this.$emit('closeModal')
      this.$emit('backToLogin')
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('email').focus()
    })
  },
}
</script>

<style src="../../../assets/css/Modals/AuthenticationModals/ForgotPasswordModal.css" scoped></style>
